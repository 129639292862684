.statistic {
    color: #122443;

    &__header {
        padding: 24px;
        background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
        border-radius: 0 0 16px 16px;
        color: #fff;

        h3 {
            color: inherit;
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.5px;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2625px;
            opacity: 0.8;
            text-align: left;
        }
    }

    &__block {
        padding: 16px;

        p {
            margin-bottom: 8px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
        }

        h5 {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.5px;
        }
    }

    &__blocks {
        & > div {
            padding: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #122443;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            button {
                padding: 8px 16px;
                width: 100%;
                background: linear-gradient(
                    270deg,
                    rgba(0, 114, 255, 0.08) 0%,
                    rgba(0, 198, 255, 0.08) 100%
                );
                color: #0072ff;
                border-radius: 7px;
                font-family: inherit;
                font-size: 16px;
                text-align: center;
                letter-spacing: 0.4px;

                a {
                    color: #0072ff;
                }
            }
        }

        &-name {
            margin-bottom: 16px;
            font-size: 16px;
            letter-spacing: 0.3px;

            span {
                font-size: 14px;
                color: rgba(18, 36, 67, 0.4);
            }
        }
    }

    &__list {
        margin-top: 8px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        &-item {
            padding: 16px;
            list-style: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.2625px;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(18, 36, 67, 0.1);
            }

            &-content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    opacity: 0.4;
                }
            }

            &-scale {
            }
        }

        li {
        }
    }

    &__answer {
        margin-top: 8px;
        padding: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: #122443;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            letter-spacing: 0.233333px;

            span {
                font-size: 14px;
                color: rgba(18, 36, 67, 0.4);
            }
        }

        &-scale {
            margin-top: 16px;
            height: 8px;
            background: #f4f7f9;
            border-radius: 4px;

            &-full {
                height: 100%;
                background-color: #47a0ff;
                border-radius: 4px;
            }
        }
    }

    &__factors {
        &-title {
            margin: 25px 0 0 0;
            padding: 0 10px;
            font-size: 20px;
            text-align: center;
        }
    }

    &__reports {
        &-wrapper {
            width: 100%;
            max-width: 640px;
            padding: 1.5rem 1.5rem 0;
        }


        &-btn {
            padding: 1rem;
            width: 100%;
            cursor: pointer;
            border-radius: 16px;
            background: $gradient-purple;
            box-shadow: 0 2px 10px -3px #8f94fb;

            p {
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.3px;
                line-height: 22px;
                text-align: center;
            }

            &-pupil-profile {
                margin: 1.5rem 1.5rem 0;
            }

            &-class {
                margin: 0 0 1.5rem 0;
            }
        }
    }

    &__filters {
        text-align: center;

        h4 {
            font-weight: 600;
        }

        &-item {
            margin-top: 10px;

            b {
                font-weight: 600;
                font-style: italic;
            }
        }
    }
}
