.respondent-page {
    width: 100%;
    max-width: 640px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    padding: 0 1.5rem 84px;
    color: #122443;

    hr {
        height: 1px;
        background-color: rgba(18, 36, 67, 0.1);
        margin: 24px 0;
    }

    &__header {
        padding: 24px;
        background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
        border-radius: 0 0 16px 16px;
        color: #fff;

        h3 {
            color: inherit;
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.5px;
        }

        p {
            color: inherit;
        }

        &-buttons {
            position: relative;
            margin-bottom: 20px;
            height: 32px;
        }

        &-center {
            text-align: center;

            h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.4px;
            }

            p {
                margin-top: 5px;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.26px;
                opacity: 0.8;
            }
        }

        &-statistic {
            text-align: center;

            h3 {
                font-weight: 600;
                font-size: 48px;
                line-height: 56px;
            }

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.2625px;
                opacity: 0.8;
            }

            &-total {
                margin-top: 40px;
            }

            &-periods {
                margin-top: 32px;
                display: flex;
                justify-content: space-around;
            }

            h4 {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.5px;
            }
        }
    }

    &__content {
        margin-top: 24px;
        padding: 0 24px;
        color: #122443;
    }

    &__button {
        width: 100%;
        display: block;
        padding: 1rem;
        color: #122443;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.32px;
        font-family: inherit;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &-with-icons {
            display: flex;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.26px;

            span {
                flex-grow: 1;
                padding: 2px 12px 0 12px;
            }

            img {
                width: 22px;
                height: 22px;
            }
        }

        &-loading {
            justify-content: center;
        }

        &-report {
            color: #fff;
            background: $gradient-purple;

            img:first-child {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__block {
        color: #122443;

        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__filter {
        margin-top: 20px;

        &-flex {
            display: flex;
            column-gap: 16px;

            & > div {
                flex: 1;
            }
        }

        h4 {
            margin-bottom: 5px;
            font-size: 16px;
            font-style: italic;
            opacity: 0.5;
            padding-left: 5px;
        }

        &-body {
            padding: 16px;
        }

        &-error {
            margin: 2rem 0;
            text-align: center;
            color: #ff5d5d;
        }

        &-date {
            &:not(:first-child) {
                margin-top: 16px;
            }

            &-flex {
                display: flex;
                align-items: center;
                column-gap: 16px;
                width: 100%;

                &-item {
                    flex: 1 1 100%;

                    & .react-datepicker-wrapper {
                        width: 100%;
                    }
                }
            }

            &-button {
                padding: 10px 15px;
                font-family: 'Gilroy', sans-serif;
                font-size: 16px;
                font-style: italic;
                letter-spacing: 0.3px;
                background-color: #f7f9ff;
                color: #808080;
                border-radius: 8px;
                border: 1px solid rgba(18, 36, 67, 0.2);
                width: 100%;

                &.active {
                    color: #333;
                    font-style: normal;
                }
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 16px;

            button {
                width: 115px;
                font-family: inherit;
                font-size: 16px;
                padding: 8px 15px;
                color: #122443;
                background-color: rgba(0, 198, 255, 0.08);
                border: 1px solid rgba(18, 36, 67, 0.2);
                border-radius: 8px;
            }

            button.active {
                background-color: #67abff;
                color: #fff;
                border: 1px solid #67abff;
            }
        }
    }

    &__list {
        &-item {
            display: block;
            padding: 16px;
            font-weight: 500;
            line-height: 22px;
            color: #122443;
            border: 1px solid rgba(128, 128, 128, 0.4);

            &.is-marked {
                border-color: #67abff;
                background-color: #f4fcff;
            }

            &.is-marked3 {
                border-color: #ff8080;
                background-color: #ffebeb;
            }

            p {
                font-size: 16px;
                letter-spacing: 0.3px;

                span {
                    font-size: 14px;
                    color: rgba(18, 36, 67, 0.4);
                }

                &:first-child {
                    margin-bottom: 8px;
                }
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &-info,
            &-title {
                display: flex;
                justify-content: space-between;
            }

            &-info {
                & .time {
                    display: flex;
                    align-items: center;

                    svg {
                        margin: 0 0 2px 3px;
                        font-size: 18px;
                        opacity: 0.7;
                    }
                }
            }
        }

        &-btn {
            cursor: pointer;
        }

        &-not-found {
            font-size: 18px;
            padding-top: 25px;
            text-align: center;
        }
    }

    &__info {
        &-item {
            span {
                font-size: 10px;
                font-weight: 600;
                line-height: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
                opacity: 0.4;
            }

            p {
                margin-top: 8px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.3px;
            }

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    &__factors {
        background-color: #f4f7f9;
        letter-spacing: 0.26px;

        h3 {
            color: inherit;
            font-size: 22px;
            line-height: 1.3;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
        }

        p:not(:last-child) {
            margin-bottom: 20px;
        }

        ul {
            margin-top: 20px;

            li {
                margin-left: 20px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        &-score {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            margin-bottom: 24px;

            span {
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.23px;
                color: #122443;
                opacity: 0.4;
            }

            p {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0.5px;
            }
        }

        &-recommendation {
            margin: 24px 0 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0.24px;
            text-transform: uppercase;
            opacity: 0.6;
        }

        &-block {
            margin-top: 8px;
            padding: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
            color: #122443;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
            cursor: pointer;

            &-title {
                margin: 0 !important;
            }

            &-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;
                font-size: 14px;
                letter-spacing: 0.233333px;

                span {
                    font-size: 14px;
                    color: rgba(18, 36, 67, 0.4);
                }
            }
        }
    }

    &__answer {
        &-title {
            font-size: 16px;
            opacity: 0.7;
        }

        &-content {
            margin-top: 10px;

            span {
                font-size: 14px;
                color: rgba(18, 36, 67, 0.45);
            }

            p {
                margin-top: 5px;
                opacity: 0.8;
            }
        }
    }

    &__result {
        &-content {
            margin-top: 30px;
            padding: 0 10px;
            color: #122443;

            &-block {
                & > div,
                & > p {
                    line-height: 150%;

                    h4 {
                        margin-bottom: 5px;
                        opacity: 0.8;
                    }

                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }

                    p:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }

                a {
                    color: #000;
                    text-decoration: underline;
                    transition: color 0.3s;

                    @media (hover: hover) {
                        &:hover {
                            color: #676767;
                        }
                    }
                }
            }

            &-reason {
                display: block;
                font-size: 14px;

                &-title {
                    margin-top: 5px;
                }
            }
        }

        &-title {
            font-size: 20px;
            line-height: 130%;
        }
    }
}

.text-center {
    text-align: center;
}

.respondents-search {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(18, 36, 67, 0.2);
    border-radius: 8px;
    overflow: hidden;

    button,
    input,
    input::placeholder {
        font-family: inherit;
        font-size: 16px;
    }

    input {
        flex: 1 1 auto;
        padding: 0 15px;
    }

    button {
        padding: 8px 15px;
        background-color: rgba(0, 198, 255, 0.08);
        color: #122443;
        transition: background 0.3s, color 0.3s;
    }

    &__buttons {
        &-tab {
            button {
                border-right: 1px solid rgba(18, 36, 67, 0.2);

                &.active {
                    background-color: rgb(103, 171, 255);
                    color: #fff;
                    transition: background 0.3s, color 0.3s;
                }
            }
        }

        &-search {
            border-left: 1px solid rgba(18, 36, 67, 0.2);
        }
    }
}
