.risk-groups {
  &__list {
    flex-grow: initial;
    margin-bottom: 3rem;

    &-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;
      padding: 1rem;
      width: 100%;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      letter-spacing: 0.3px;
      color: #122443;
      border-radius: 16px;
      background-color: #fff;
      box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

      & + & {
        margin-top: 13px;
      }
    }
  }

  &__btn {
    cursor: pointer;
  }

  &__filter {
    position: absolute;
    background-color: #F4F7F9;
    right: 0;
    top: 40px;
    z-index: 100;
    box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.4);
    border-radius: 10px;

    &-item {
      display: flex;
      padding: 0 15px;
      transition: background-color 0.3s;

      & + & {
        border-top: 1px solid rgba(18, 36, 67, 0.1);
      }

      input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
      }

      input[type="checkbox"] + label {
        width: 300px;
        display: block;
        cursor: pointer;
        position: relative;

        color: #122443;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;

        &::after {
          top: 50%;
          left: 0;
          position: absolute;
          display: block;
          content: "";
          width: 22px;
          height: 22px;
          border-radius: 4px;
          transform: translateY(-50%);
          background: rgba(18, 36, 67, 0.06);
        }
      }

      input[type="checkbox"]:checked + label {
        &::after {
          background: #fff;
          box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        }

        &::before {
          top: 50%;
          left: 0;
          z-index: 1;
          position: absolute;
          display: block;
          content: "";
          width: 22px;
          height: 22px;
          transform: translateY(-50%);
          background: url(../img/ic-check.svg) no-repeat center;
        }
      }

      label {
        padding: 10px 0 10px 40px;
        text-align: left;
      }

      &:hover {
        background-color: #dfe6ea;
      }
    }

    @media (max-width: 379px) {
        max-width: 310px;
    }
  }

  &__tag {
    display: inline-block;
    padding: 0 0.5rem;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: $gradient-red;
  }

  &__school {
    font-size: 14px;
    line-height: 100%;
    font-weight: normal;
    color: #122443;

    span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
}