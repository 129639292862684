.landing {
    width: 100%;
    background: #fff;
}
.landing-header__auth div.landing-header__link.landing-header__dropdown:hover{
    text-decoration: none;
    cursor: pointer;
}
.landing-header__auth div.landing-header__link.landing-header__dropdown:hover .landing-header__linkContent-holder{
    display: flex;
}
.landing-header__auth .landing-header__linkContent a:last-child, .landing-header__auth .landing-header__linkContent a:nth-child(2){
    padding: 0;
    background-color: transparent;
    margin-left: 0;
    line-height: 21px;
    padding-top: 8px;
    border: 1px solid #F5F5F5;
    margin-top: 8px;
}
.landing-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem;
    padding-left: 70px;
    padding-right: 80px;

    @media (max-width: 640px) {
        padding: 1.5rem;
    }

    &__logo-wrapper {
        width: 123px;
        margin-right: 30px;
    }

    &__dropdown{
        position: relative;
    }
    &__linkContent-holder{
        position: absolute;
        top: 18px;
        left: -90px;
        padding-top: 16px;
        display: none;
    }

    &__linkContent{
        background: #F5F5F580;
        padding: 12px 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
    }
    &__logo {
        display: block;
        width: 123px;
        height: 34px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left center;
        }

        @media (max-width: 640px) {
            width: 9rem;
            height: 2.5rem;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        flex-basis: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        @media (max-width: 1024px) {
            display: none;
        }
        @media (max-width: 1300px) {
            flex-wrap: wrap;
        }
        justify-content: center;
        row-gap: 10px;
    }
    .noMargin{
        margin-left: 0!important;
    }
    &__link {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #2330ba;
        transition: opacity 0.3s;
        width: max-content;

        & + & {
            margin-left: 40px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
    &__linkWrapper {
        position: relative;
        margin-left: 32px;

        span {
            position: absolute;
            bottom: -20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            font-size: 15px;
            font-style: italic;
            color: #122443;
            opacity: 0.7;
            text-align: center;
        }
    }

    &__linkButton {
        font-size: 18px;
        font-weight: 500;
        text-align: left;

        color: #2330ba;
        transition: opacity 0.3s;
        width: max-content;

        padding: 0 25px;
        line-height: 56px;
        border-radius: 12px;
        background: rgba(17, 101, 250, 0.1);

        & + & {
            margin-left: 40px;
        }

        &:hover {
            opacity: 0.7;
        }
    }
    &__auth {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-basis: 25%;

        a:last-child {
            padding: 0 25px;
            line-height: 56px;
            border-radius: 12px;
            background: rgba(17, 101, 250, 0.1);
            margin-left: 32px;
        }

        @media (max-width: 640px) {
            display: none;
        }
    }

    &__mobMenu {
        width: 2rem;
        height: 2rem;
    }
}

.landing-main {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 4rem calc((100% - 1140px) / 2) 0;

    @media (max-width: 1220px) {
        padding: 4rem 2.5rem 0;
    }

    @media (max-width: 640px) {
        padding: 30px 1.5rem 0;
    }

    &__title {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        text-align: center;
        color: #2330ba;
        margin-bottom: 2.5rem;

        @media (max-width: 1024px) {
            font-weight: 600;
            font-size: 56px;
            line-height: 110%;
        }

        @media (max-width: 640px) {
            font-weight: 600;
            font-size: 26px;
            line-height: 110%;
            margin-bottom: 23px;
        }
    }
    &__row{
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 640px) {
            flex-direction: column-reverse;
        }
    }
    &__text{
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #2330BA;
        width: 1210px;
        margin-bottom: 37px;
        .mob-br{
            display: none!important;
        }
        span{
            font-weight: 600;
            letter-spacing: 0.03em;
            text-align: center;
        }
        @media (max-width: 1100px) {
            width: 100%;

            br{
                display: none;
            }

        }
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            text-align: center;

            .mob-br{
                display: block!important;
            }
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        padding-bottom: 27px;
        @media (max-width: 640px) {
            flex-flow: column;
            padding-bottom: 3rem;
        }
    }
    &__freemium{
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.03em;
        text-align: right;
        color: #122443;
        text-decoration: underline;
        padding-bottom: 5rem;
    }
    &__btn-wrapper {
        position: relative;
        margin-left: 1.5rem;

        @media (max-width: 640px) {
            margin-left: 0;
            margin-top: 12px;
        }

        span {
            position: absolute;
            bottom: -20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            font-size: 15px;
            font-style: italic;
            color: #122443;
            opacity: 0.7;
            text-align: center;
        }
    }

    &__btn {
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 56px;
        color: #122443;

        padding: 0 2rem;
        background: #fecc62;
        border-radius: 12px;

        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }

        &_blue {
            color: #2330ba;
            background: rgba(17, 101, 250, 0.1);
        }
    }

    &__bnr {
        width: 100%;
        overflow: hidden;
        border-radius: 42px;

        img {
            width: 100%;
        }

        @media (max-width: 640px) {
            height: 235px;
            border-radius: 28px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__video {
        width: 100%;
        max-width: 1140px;
        overflow: hidden;
        border-radius: 42px;
        position: relative;

        video {
            width: 100%;
            height: auto;
        }

        @media (max-width: 640px) {
            border-radius: 28px;
        }
    }

    &__videoControls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../img/ic-play.svg) no-repeat center / 120px;
        cursor: pointer;

        @media (max-width: 1024px) {
            background-size: 5rem;
        }

        &_actively {
            background: transparent;
        }
    }
}

.landing-bullets {
    width: 100%;
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 40px calc((100% - 1140px) / 2) 45px;

    @media (max-width: 1220px) {
        padding: 94px 2.5rem 7.5rem;
    }

    @media (max-width: 640px) {
        padding: 5rem 0rem 20px;
    }
}

.landing-bullet {
    margin: 0 auto 2rem;
    width: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;

    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #2330ba;
    &__text{
        width: 300px;
    }
    &__icon {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f0fcff;
        margin-bottom: 20px;
        border-radius: 50%;
    }
}

.landing-about{
    margin-top: 80px;
}
.landing-about,
.landing-goal {
    width: 100%;
    background: rgba(0, 198, 255, 0.06);
    padding: 100px calc((100% - 1140px) / 2) 140px;

    font-size: 29px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #2330ba;

    @media (max-width: 1220px) {
        padding: 100px 2.5rem 140px;
    }

    @media (max-width: 640px) {
        font-size: 18px;
        padding: 40px 30px 40px 30px;
    }

    &__row{
        display: flex;
        margin-top: 72px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: 900px) {
            flex-direction: column;
        }
        @media (max-width: 640px) {
            margin-top: 40px;
        }
    }
    &__prop{
        @media (max-width: 1200px) {
            img{
                height: 50px;
            }
        }
        @media (max-width: 900px) {
            img{
                height: 30px;
                margin-right: 20px;
            }
            display: flex;
            align-items: center!important;
            flex-direction: row!important;
        }
    }

    &__leftCol{
        max-width: 382px;
        height: 779px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            margin-top: 25px;
        }
        @media (max-width: 1200px) {
            height: 600px;
            width: 250px;
            p{
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
            }
        }
        @media (max-width: 900px) {
            margin-bottom: 60px;
            height: 300px;
            width: 400px;
            br{
                display: none;
            }
            p{
                margin: 0;
            }
        }
        @media (max-width: 640px) {
            width: 307px;
            height: 340px;
            margin-bottom: 40px;
        }
    }
    &__rightCol{
        max-width: 372px;
        height: 779px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        div{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        p{
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: right;
            margin-top: 25px;
        }
        @media (max-width: 1200px) {
            p{
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
            }
            height: 600px;
            width: 250px;
        }
        @media (max-width: 900px) {
            margin-top: 60px;
            margin-bottom: 60px;
            height: 300px;
            width: 400px;
            align-items: flex-start;
            br{
                display: none;
            }
            p{
                margin: 0;
                text-align: left;
            }
        }
        @media (max-width: 640px) {
            width: 307px;
            margin-top: 40px;
            height: 380px;
            margin-bottom: 0;
        }
    }
    &__middleCol{
        img{
            width: 352px;
        }
        @media (max-width: 1200px) {
            img{
                width: 300px;
            }
        }
        @media (max-width: 900px) {

        }
    }
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        margin-bottom: 1.5rem;

        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 48px;
        }
    }
    &__title-center {
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        margin-bottom: 0;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 48px;
        }
    }
    &__list-item{
        margin-bottom: 20px;
        padding-left: 5px;
        margin-left: -5px;

        @media (max-width: 640px){
            padding-left: 0;
            margin-left: 0;
        }
    }
}
.landing-goal__title{
    margin-bottom: 55px;

    @media (max-width: 640px) {
        font-size: 38px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 37px;

    }
}
.landing-goal{

    @media (max-width: 640px) {
        background: transparent!important;
        padding-top: 0;

        &__title{
            display: none;
        }
    }

    &__list-item{
        margin-bottom: 0;
    }
    &__list{
        display: flex;
        flex-direction: column;

        label{
            font-size: 30px;
            font-style: italic;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 0;
            text-align: right;
            color: #0F1445;
            margin-left: auto;
            margin-top: 32px;
            @media (max-width: 640px) {
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: right;
                margin-top: 0;
            }
        }
        p{
            margin-bottom: 35px;
            font-size: 28px;
            font-weight: 500;
            line-height: 42px;
            letter-spacing: 0.03em;
            text-align: left;
            @media (max-width: 640px) {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
            }
        }
        span{
            font-size: 28px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.03em;
            text-align: left;
            @media (max-width: 640px) {
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
            }

        }
    }
}

.landing-goal {
    background: #fffef0;
}
.forMob{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 280px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #122443;
    background: #FECC62;
    border-radius: 12px;
    transition-duration: 300ms;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    @media (min-width: 900px) {
        display: none;
    }
}
.quiz{
    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
.landing-services .alertBlock{
    background: #FFFDF0;
    padding: 48px 58px;
    border-radius: 50px;

    @media (max-width: 640px) {
        padding: 30px 10px 30px 30px;
        border-radius: 38px;
    }
}

.alertBlock{
    margin-bottom: 128px;
    &__row{
        display: flex;

        img{
            margin-right: 60px;
            height: 62px;
            margin-top: 16px;
            width: 64px;
        }
        p{
            color: #2330BA;
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0.03em;
            text-align: left;
            margin-top: 27px;

        }
        @media (max-width: 640px) {
            img{
                width: 28px;
                height: 28px;
                margin-bottom: 19px;
                margin-top: 0;
            }
            p{
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.03em;
                text-align: left;
                margin-top: 0;
            }
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }
    .p-2{
        color: #2330BA;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-top: 27px;
    }
    @media (max-width: 640px) {
        .p-2{
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.03em;
            text-align: left;

        }
    }
}

#menuOpener{
    position: absolute;
    top: 34px;
    right: 32px;
    cursor: pointer;
}

#menuOpener:hover{
    opacity: 0.7;
}

#menu{
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #122443;
    z-index: 10;
    transition-duration: 500ms;

    @media (min-width: 640px) {
        display: none!important;
    }
}
#menuOpener{
    @media (min-width: 640px) {
        display: none!important;
    }
}
.menu{
    top: calc(-100% - 663px);
}
.menu.opened{
    top: 0;
}
.menu{
    &__row{
        display: flex;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        margin-bottom: 50px;

        button{
            width: 39px;
            height: 39px;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 50%;
            transition-duration: 300ms;

            &:hover{
                opacity: 0.7;
            }
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        height: calc(100% - 119px);
        padding-bottom: 36px;
    }
    &__loginBtn{
        margin-top: 40px;
        width: 168px;
    }
    &__freeBtn{
        position: relative;
        margin-top: 16px;
        width: 188px;

        span {
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            font-style: italic;
            opacity: 0.8;
        }
    }
    &__loginBtn, &__freeBtn{
        border: 2px solid #FFFFFF;

        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: transparent;
        border-radius: 12px;
        margin-left: auto;
        margin-right: 30px;
        transition-duration: 300ms;

        &:hover{
            background-color: white;
            color: #122443;
        }
    }
    &__contacts{
        margin-top: auto;
        padding-left: 20px;

        &-row{
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            a{
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
                color: white;
                text-decoration: none;
                cursor: pointer;
            }
            a:hover{
                text-decoration: underline;
            }
        }
        &-imgHolder{
            width: 39px;
            height: 39px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;
            background-color: #FFFFFF0F;
            margin-right: 20px;
        }
    }
    &__links{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 30px;

        a{
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0;
            color: white;
            text-align: left;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 7px;
        }
        a:hover{
            text-decoration: underline;
        }
    }
}
.landing-services {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10rem calc((100% - 1140px) / 2);
    padding-bottom: 6rem;

    @media (max-width: 1220px) {
        padding: 7rem 2.5rem;
    }

    @media (max-width: 1024px) {
        flex-flow: column;
        justify-content: initial;
    }

    @media (max-width: 640px) {
        padding: 2rem 1rem;
        padding-bottom: 10px;
    }
    &__title{
        font-size: 72px;
        font-weight: 600;
        line-height: 79px;
        letter-spacing: 0;
        text-align: left;
        color: #2330BA;
        margin-bottom: 77px;

        br{
            display: none;
        }
        @media (max-width: 640px) {
            font-size: 38px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 30px;

            br{
                display: block;
            }
        }
    }
    &__buttons{
        width: 100%;
        display: flex;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 100px;
        @media (max-width: 1100px) {
            margin-bottom: 50px;
        }
        @media (max-width: 900px) {
            margin-bottom: 40px;
        }

    }

    &__methodologistRow{
        display: flex;
        margin-bottom: 60px;
        
        @media (max-width: 1100px) {
            flex-direction: column-reverse;
        }

        @media (max-width: 640px) {
            margin-bottom: 20px;
        }
    }
    &__blockText{
        display: flex;
        flex-direction: column;
        @media (max-width: 640px) {
            margin-bottom: 20px;
        }
        .rowDot{
            display: flex;
            margin-bottom: 25px;
            div{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 20px;
                margin-top: 12px;
            }
            .dot-blue{
                background: #47A0FF;
            }
            .dot-yellow{
                background: #FECC62;
            }
            .dot-red{
                background: #FF8D4D;
            }

            p{
                font-size: 26px;
                font-weight: 500;
                line-height: 31px;
                letter-spacing: 0.04em;
                text-align: left;
                color: #2330BA;
            }
            .p-390{
                max-width: 390px;
            }
            .p-475{
                max-width: 475px;
            }
            @media (max-width: 900px) {
                align-items: center;
                div{
                    margin-top: 0;
                }
                p{
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.04em;
                    text-align: left;
                }
                .p-390{
                    max-width: 294px;
                }
                .p-475{
                    max-width: 294px;
                }
            }

        }

        button{
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            width: 280px;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
            color: #122443;
            background: #FECC62;
            border-radius: 12px;
            transition-duration: 300ms;
            @media (max-width: 900px) {
                display: none;
            }
        }
        button:hover{
            background: rgba(254, 204, 98, 0.71);
        }
    }
    &__blocksContent{
        margin-bottom: 207px;

        @media (max-width: 640px) {
            margin-bottom: 80px;
        }
    }
    &__blockContent{
       display: flex;
        justify-content: space-between;

        img{
            width: 50%;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50px;
        }

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;

            img{
                width: 312px;
            }
        }

    }
    &__button:nth-child(2), &__button-active:nth-child(2){
        border-left: 1px solid #122443;
        border-right: 1px solid #122443;
    }
    &__button:hover{
        background: rgba(17, 101, 250, 0.22);
    }
    &__button-active:hover{
        background: rgba(254, 204, 98, 0.71);
    }

    &__button{
        width: calc(100% / 3);
        height: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-align: center;
        text-transform: uppercase;
        background: #1165FA1A;
        color: #2330BA;
        transition-duration: 300ms;
        

        &-active{
            width: calc(100% / 3);
            height: 107px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FECC62;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.08em;
            text-align: center;
            text-transform: uppercase;
            transition-duration: 300ms;
        }
        @media (max-width: 1100px) {
            height: 67px;

            &-active{
                height: 67px;
            }
        }
        @media (max-width: 640px) {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.08em;
            text-align: center;
            height: 40px;

            &-active{
                height: 40px;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.08em;
                text-align: center;

            }
        }
    }

    &__method-text{
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #2330BA;
        margin-bottom: 35px;
        @media (max-width: 640px) {
            font-family: Gilroy;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.04em;
            text-align: left;
            width: 313px;
        }
    }
    &__quiz-text{
        margin-bottom: 37px;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #122443;

        @media (max-width: 640px) {
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.04em;
            text-align: left;
            width: 313px;
        }
    }
    &__quizRow{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 151px;
        max-width: 945px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 640px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 105px;
        }
        .col{
            display: flex;
            align-items: center;
            flex-direction: column;

            @media (max-width: 640px) {
                margin-bottom: 50px;
            }

            p{
                font-size: 26px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 0.04em;
                text-align: center;
                color:#2330BA;
                max-width: 228px;
                @media (max-width: 640px) {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0.04em;
                    text-align: center;

                }
            }
            div{
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .numberHolder-big{
                width: 111px;
                height: 111px;
                font-size: 32px;
                font-weight: 900;
                line-height: 36px;
                letter-spacing: 0.08em;
                text-align: center;
                max-width: 228px;
                background-color: #2330BA;
                color: white;
                margin-bottom: 21px;
            }
            .numberHolder-small{
                background-color: #F0FCFF;
                width: 80px;
                height: 80px;
                font-size: 32px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0.08em;
                text-align: left;
                color: #2330BA;
                margin-bottom: 21px;
            }

        }
    }

    &__methodologistRow{
        justify-content: space-between;
    }
    &__methodologist{
        width: calc(50% - 20px);
        padding: 40px;
        border-radius: 50px;
        background-color: #F0FCFF;
        position: relative;



        @media (max-width: 1100px) {
            width: 100%;
            margin-bottom: 40px;
            &:last-child{
                background-color: #FFFDF0;
            }
           
        }
        
        @media (max-width: 740px) {
            img{
                width: 100px!important;
                height: 100px!important;
                right: 18px!important;
                top: 36px!important;

            }
        }
        @media (max-width: 640px){
            padding: 40px 10px;
            margin-bottom: 20px;
            h3{
                margin-left: 10px;
            }

            &:first-child{
                background-color: #F0FCFF;
            }
        }
        span{
            font-size: 26px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.04em;
            text-align: right;

            @media (max-width: 900px) {

                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
            }
        }

        h3{
            font-size: 38px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #122443;
            margin-left: 15px;
            @media (max-width: 900px) {
                font-size: 26px;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-left: 20px;
            }
        }
        ul{
            list-style-position: outside;

        }
        li {
            font-size: 26px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0.04em;
            text-align: left;
            margin-bottom: 10px;
            padding-left: 10px;
            margin-left: 35px;

            @media (max-width: 900px) {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;

            }
            @media (max-width: 640px) {
                margin-bottom: 20px;

            }
        }
        img{
            width: 126px;
            position: absolute;
            top: 43px;
            right: 43px;
        }
        .mob{
            display: none;

            @media (max-width: 900px) {
               display: block;
                width: 100px;
                top: 30px;
                right: 30px;
            }
        }
    }


    &__contentBlock{
        width: 100%;
        display: flex;
        padding: 40px;
        background-color: #FFFDF0;
        border-radius: 50px;
        margin-bottom: 40px;

        

        img{
            width: 50px;
            height: 50px;
            margin-right: 50px;
        }

        h4{
            font-size: 38px;
            font-weight: 600;
            line-height: 53px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2330BA;
            margin-bottom: 50px;
        }
        li{
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2330BA;
            padding-left: 5px;
            margin-left: -5px;
        }

        @media (max-width: 640px) {
            padding: 30px 20px;
            img{
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h4{
                font-size: 26px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-bottom: 20px;
            }
            ul{
                margin-left: -19px;
            }
            li{
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }

    &__contentBlock-blue{
        background-color: #F0FCFF;


        @media (max-width: 640px){
            background-color: #FFFDF0;
            padding-top: 20px;

            img{
                margin-top: 15px;
            }
        }
    }
    &__docsTitle{
        margin-bottom: 27px;
    }
    &__docsText{
        font-size: 25px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-bottom: 37px;
        color: #2330BA;
        @media (max-width:640px) {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            text-align: left;
        }

    }
    &__docsRow{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        row-gap: 27px;

        @media (max-width:800px) {
            flex-direction: column;

            & > div {
                &:nth-child(odd) {
                    background-color: #FFFDF0;
                }
                &:nth-child(even) {
                    background-color: #F0FCFF;
                }
            }
        }

    }
    &__docsCol-yellow{
        background-color: #FFFDF0;
    }
    &__docsCol-blue{
        background-color: #F0FCFF;

    }
    &__docsCol{
        width: calc(50% - 25px);
        align-items: center;
        display: flex;
        padding: 13px 23px;
        border-radius: 20px;

        img{
            height: 42px;
            width: 42px;
        }
        a{
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #122412!important;
            text-decoration: none;
            display: block;
            margin-left: 27px;

        }
        a:hover{
            text-decoration: underline;
        }

        @media (max-width:800px) {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
        @media (max-width:640px) {
            width: 329px;

            img{
                width: 16px;
                height: 16px;

            }
            a{
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-left: 20px;
            }
        }

    }

    &__cover {
        width: 450px;
        height: 450px;
        overflow: hidden;
        border-radius: 42px;
        margin-right: 2.5rem;

        @media (max-width: 1024px) {
            width: 100%;
            height: auto;
            margin-right: initial;
        }

        @media (max-width: 640px) {
            border-radius: 29px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &__content {
        max-width: 570px;
        padding-top: 3rem;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #2330ba;

        @media (max-width: 1024px) {
            display: flex;
            flex-flow: column;
            align-items: center;
            padding-top: 2rem;
            max-width: initial;
            text-align: center;
        }

        @media (max-width: 640px) {
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__steps {
        display: flex;
        margin-bottom: 2.5rem;

        @media (max-width: 1024px) {
            order: 1;
            margin-top: 2.5rem;
            margin-bottom: 0;
        }
    }

    &__step {
        display: flex;
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        background: #f0fcff;
        border-radius: 50%;

        font-weight: 600;
        font-size: 32px;
        line-height: 111%;
        letter-spacing: 0.08em;
        color: #2330ba;

        @media (max-width: 640px) {
            width: 62px;
            height: 62px;
            font-size: 26px;
        }

        & + & {
            margin-left: 60px;

            @media (max-width: 640px) {
                margin-left: 2.5rem;
            }
        }

        &_blue {
            color: #ffffff;
            background: #2330ba;
        }

        &_yellow {
            color: #ffffff;
            background: #ffbe4d;
        }

        &_orange {
            color: #ffffff;
            background: #ff8d4d;
        }
    }
}

.landing-contacts {
    width: 100%;
    background-color: #0f1445;
    background-repeat: no-repeat;
    background-image: url(../img/ellipse.svg);
    background-position: left -77px bottom -128px;
    padding: 120px calc((100% - 1140px) / 2) 0;

    @media (max-width: 1220px) {
        padding: 120px 2.5rem 0;
    }

    @media (max-width: 640px) {
        padding: 5rem 1.5rem 0;
        padding-top: 40px;
    }

    &__title {
        color: #fff;
        font-size: 72px;
        font-weight: 600;
        line-height: 110%;
        margin-bottom: 5rem;

        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 26px;
            font-weight: 600;
            line-height: 110%;
            margin-bottom: 30px
        }
        &_contacts{
            font-size: 48px;
            line-height: 70%;
            margin-bottom: 2.5rem;
        }
    }

    &__content {
        width: 100%;
        display: flex;

        @media (max-width: 1024px) {
            flex-flow: column;
        }

    }

    &__contacts {
        @media (max-width: 1024px) {
            order: 1;
        }
    }
    &__label{
        margin-top: 2.5rem;
        font-size: 18px;
        color: white;
        opacity: 0.5;
    }
    &__img{
        margin-top: 5rem;
        width: 180px;
        filter: brightness(0) invert(1);
    }
    &__img2{
        margin-top: 5rem;
        margin-left: 8rem;
        height: 80px;

        @media (max-width: 640px) {
            margin-left: 3rem;
            margin-top: 5rem;
        }
        @media (max-width: 388px) {
            margin-left: 2rem;
            margin-top: 5rem;
        }
        @media (max-width: 372px) {
            margin-left: 0rem;
            margin-top: 5rem;
        }
        @media (max-width: 339px) {
            margin-left: -0.5rem;
            margin-top: 5rem;
        }
        @media (max-width: 331px) {
            margin-left: -0.5rem;
            margin-top: 2rem;
        }
        @media (max-width: 323px) {
            margin-left: -1rem;
            margin-top: 2rem;
        }
    }
    &__contact {
        display: flex;
        align-items: center;
        font-family: Gilroy;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #ffffff;


        &::before {
            content: "";
            display: block;
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 20px;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.06);
        }

        &_email::before {
            background-image: url(../img/ic-email.svg);
        }
        &_email{
            margin-top: 25px!important;
            &:nth-child(2):before{
                width: 36px;
                height: 36px;
                background-size: 15px 15px;
                margin-right: 28px;
                margin-left: 11px;
            }
            &:nth-child(3):before{
                width: 36px;
                height: 36px;
                background-size: 15px 15px;
                margin-right: 28px;
                margin-left: 11px;
            }
            display: flex;
            div{
                display: flex;
                flex-direction: column;
            }
            p{
                display: block;
            }
            label{
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
                display: block;
            }
        }
        &_tel::before {
            background-image: url(../img/ic-tel.svg);
        }
        &_address{
            align-items: flex-start;
            cursor: initial;
        }
        &_address::before {
            background-image: url(../img/ic-loc.svg);
            min-width: 3.5rem;
        }

        & + & {
            margin-top: 2.5rem;
        }
    }

    &__form-wrapper {
        width: 50%;
        margin-left: auto;

        @media (max-width: 1024px) {
            width: 100%;
            margin-left: initial;
            margin-bottom: 2.5rem;
        }
    }

    &__form {
        width: 100%;
        padding: 2.5rem;
        background: #fff;
        border-radius: 32px;

        @media (max-width: 1024px) {
            padding: 1rem;
            border-radius: 1rem;
        }
    }

    &__form-steps {
        display: flex;
        margin-bottom: 2rem;

        span {
            font-size: 14px;
            letter-spacing: 0.03em;
            color: rgba(35, 48, 186, 0.4);

            padding: 0 20px;
            line-height: 33px;
            border-radius: 1.5rem;
        }

        span:first-child {
            color: #fff;
            background: #030849;
        }
    }

    &__input-field {
        display: flex;
        flex-flow: column;
        margin-bottom: 1.5rem;

        label {
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.03em;
            margin-bottom: 0.5rem;
            color: rgba(35, 48, 186, 0.6);
        }

        input {
            color: #2330ba;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.03em;

            padding: 15px 20px;
            border-radius: 12px;
            border: 0.5px solid rgba(35, 48, 186, 0.4);
        }

        input::placeholder {
            color: rgba(35, 48, 186, 0.4);
        }
    }

    &__form-note {
        display: flex;
        align-items: center;

        padding: 1rem 20px;
        border-radius: 12px;
        background: rgba(74, 135, 239, 0.05);

        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.03em;
        color: rgba(35, 48, 186, 0.8);

        margin-bottom: 2.5rem;

        &::before {
            content: "";
            min-width: 20px;
            height: 25px;
            display: block;
            margin-right: 20px;
            background: url(../img/ic-shield.svg) no-repeat center / contain;
        }
    }

    &__form-btns {
        display: flex;
    }

    &__form-btn {
        cursor: pointer;
        height: 53px;
        flex-grow: 1;
        font-size: 18px;
        line-height: 150%;
        border-radius: 8px;
        letter-spacing: 0.03em;
        transition: opacity 0.3s;

        &_back {
            background: rgba(0, 114, 255, 0.1);
            color: #2330ba;
        }

        &_forward {
            background: #fecc62;
            color: #122443;
        }

        & + & {
            margin-left: 10px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__col{
        display: flex;
        flex-direction: column;
        @media (max-width: 1025px) {
            margin-right: auto;
            margin-top: 20px;
        }
    }
    &__p{
        margin-bottom: 20px;
    }

    &__p1{
        max-width: 305px;
    }
    &__p2{
        max-width: 330px;
    }
    &__p3{
        width: 282px;
        margin-left: auto;
        text-align: right;
        
        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
        }
    }
    &__p4{
        width: 282px;
        margin-left: auto;
        text-align: right;
        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
        }
    }
    &__row{
        display: flex;
        align-items: center;
    }
    &__row1{
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }
    &__row3{
        margin-bottom: auto;
        a{
            transition-duration: 300ms;
            opacity: 0.3;
        }
        a:hover{
            opacity: 1;
            text-decoration: underline;
        }
        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    &__p3{
        margin-top: 36px;
    }
    &__p4{

        a{
            transition-duration: 300ms;
            color: white;
        }
        a:hover{
            color: white;
            opacity: 1!important;
            text-decoration: underline;
        }
    }

    &__img1{
        margin-right: 15px;
    }
    &__img3{
        margin-right: 18px;
    }
    &__footer {
        display: flex;
        padding: 4rem 0;
        padding-bottom: 40px;

        @media (max-width: 1024px) {
            flex-flow: column;
            align-items: center;
        }

        p,
        a {
            opacity: 0.5;
            color: #fff;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.03em;
        }

        p:first-child {
            margin-right: auto;

            @media (max-width: 1024px) {
                margin-right: initial;
            }
        }

        a + a {
            margin-left: 2.5rem;

            @media (max-width: 1024px) {
                margin-left: initial;
            }
        }
    }
}
.landing-popup{
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000099;
    justify-content: center;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 100px;
    overflow: scroll;
    z-index: 11;
    overflow-x: hidden;
}

.animatedCheckbox{
    display: flex;
    margin-bottom: 11px;

    .checkbox{
        width: 18px;
        height: 18px;
        border: 1px solid #2330BA;
        border-radius: 2px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 3px;
        cursor: pointer;

        svg{
            position: absolute;
        }
    }

    p{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        max-width: 440px;
        color: rgba(17, 36, 68, 0.4);


        a{
            color: rgba(17, 36, 68, 1);
        }

        @media (min-width: 1120px) and (max-width: 1180px){
            max-width: 350px;
        }
        @media (max-width: 520px) {
            max-width: 210px;
        }
    }

    &__password {
        .checkbox {
            margin-top: 0;
            background-color: white;

            &.checked {
                background-color: #2330BA;
            }
        }

        p {
            font-size: 14px;
            color: #122443;
            opacity: 0.5;
        }
    }
}
