.editing-block {
    &__title {
        margin: 0;
        text-align: center;

        &.top-margin {
            margin-top: 20px;
        }
    }
}

.editable {
    &-block {
        padding: 10px;
    }

    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__fields {
            flex-grow: 1;
        }

        & .title,
        & .subtitle,
        span,
        input,
        textarea {
            width: 100%;
            padding: 10px;
            font-family: inherit;
            font-size: 20px;
            font-weight: 500;
            line-height: 130%;
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 8px;
            background-color: #f7f9ff;

            &.edit-subtitle {
                font-size: 18px;
                font-weight: 400;
            }
        }

        & .title,
        & .subtitle {
            border-color: #fff;
            background-color: inherit;
        }

        & .subtitle {
            font-size: 18px;
            font-weight: 400;
        }

        img {
            width: 20px;
            height: 20px;
            opacity: 0.6;
        }

        button {
            padding: 2px;
        }

        &__buttons-block {
            display: flex;
            justify-content: space-between;
            column-gap: 5px;
            padding-left: 10px;
            padding-right: 5px;
        }
    }

    &__blocking-answer {
        label {
            padding: 10px 15px 10px 40px;
            background-color: transparent !important;
        }

        input {
            &:checked + label {
                background-color: transparent !important;
            }
        }
    }
}

.questions {
    &__list {
        margin-top: 15px;
    }

    &__item {
        position: relative;
        display: block;
        margin-top: 8px;
        padding: 16px 60px 16px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.3px;
        color: rgba(18, 36, 67, 0.8);

        &:after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 16px;
            width: 22px;
            height: 22px;
            background-image: url('../img/btn-right.svg');
        }
    }
}

.editing {
    &__add-answer {
        padding: 25px 0 10px;
        text-align: center;
    }
}

.editing-answers {
    &__list {
        margin-top: 15px;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: 0.3px;
        color: rgba(18, 36, 67, 0.8);

        &.hidden {
            background-color: #dedede;
        }

        &-content {
            flex-grow: 1;
            padding-right: 25px;
            color: inherit;

            &-title {
                span {
                    display: block;
                    margin-top: 2px;
                    opacity: 0.7;
                }
            }

            &-recommendations {
                margin-top: 10px;
            }
        }

        p {
            margin-top: 2px;
            font-size: 14px;
            letter-spacing: 0.233333px;
            span {
                font-size: 14px;
                color: rgba(18, 36, 67, 0.4);
            }
        }

        &-buttons-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 15px;

            button {
                opacity: 0.7;
                padding: 2px;
            }
        }
    }
}

.add-answer {
    margin-top: 30px;
}

.editing-accounts {
    &__add-user {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }

    &__list {
        margin-top: 24px;

        &-not-found {
            margin: 24px 0;
            text-align: center;
        }
    }

    &__form {
        &-add-btn {
            margin-top: 24px;
            text-align: center;
        }

        &-item {
            margin-top: 8px;
        }

        &-sub {
            margin-left: 30px;
        }

        hr {
            margin: 16px 0;
        }
    }

    &__info {
        &-content {
            & h4:not(:first-child) {
                margin-top: 18px;
            }
        }

        &-history {
            position: relative;
            height: 500px;
            overflow-y: auto;
            font-weight: 600;

            &-item {
                font-size: 14px;
                padding: 3px 0;

                span {
                    font-size: 16px;
                    font-style: italic;
                    font-weight: 400;
                    color: rgba(18, 36, 67, 0.6);
                }

                a {
                    font-size: 14px;
                    font-weight: 400;
                }

                &:not(:first-child) {
                    border-top: 1px solid rgba(128, 128, 128, 0.4);
                }
            }
        }

        &-edit-btn {
            margin-top: 20px;
            text-align: center;
        }
    }

    &__regions {
        &-header {
            display: flex;
            align-items: center;
            column-gap: 8px;

            h4 {
                flex-grow: 1;

                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            button {
                padding: 5px 10px;
                font: inherit;
                font-size: 14px;
                letter-spacing: 0.2px;
                color: inherit;
                border: 1px solid rgba(18, 36, 67, 0.2);
                border-radius: 5px;
            }
        }

        &-search {
            margin-top: 8px;
            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 10px 15px;
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 8px;

            input {
                width: 100%;
                height: 21px;
                font: inherit;
                color: #122443;

                &::placeholder {
                    font-style: italic;
                    opacity: 0.8;
                }
            }

            button {
                padding: 2px;
            }

            &-not-found {
                margin-top: 12px;
                text-align: center;
            }
        }

        &-block {
            overflow-y: auto;
            overflow-x: hidden;
            height: 350px;
            position: relative;

            &::-webkit-scrollbar {
                width: 6px;
                background-color: rgba(19, 19, 19, 0.15);
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(19, 19, 19, 0.25);
            }

            &-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            &-inner {
                position: absolute;
                padding: 0 10px 10px 0;
                width: 98%;

                h4 {
                    margin-bottom: 8px;

                    &:not(:first-child) {
                        margin-top: 12px;
                    }
                }

                & > div {
                    h5 {
                        font-size: 14px;
                        padding-left: 10px;
                        padding-bottom: 3px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        &-item {
            border: 1px solid rgba(18, 36, 67, 0.2);

            label {
                font-size: 14px;
                padding: 10px 15px 10px 40px;
            }
        }
    }
}

.editing-filters {
    &__wrapper {
        & > div:not(:first-child) {
            margin-top: 12px;
        }
    }
}
