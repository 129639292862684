.diagram-handler {
    cursor: pointer;

    font-size: 14px;
    line-height: 22px;

    align-self: center;
    text-align: center;
    margin-bottom: 1.5em;
}

.diagram {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    box-shadow: 0px 2px 10px -3px rgba(38, 51, 73, 0.1);

    &__title {
        color: #122443;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.3px;
        margin-bottom: 1.5rem;
    }

    &__condition-name {
        color: #122443;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        margin: 2rem 0 11px;
    }

    &__details {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & + & {
            margin-top: 12px;
        }
    }

    &__details-key {
        opacity: 0.4;
        color: #122443;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.233333px;
    }

    &__details-value {
        color: #122443;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.233333px;
    }
}

.chart {
    width: 100%;
    height: 278px;

    background: url(../img/chart.svg) no-repeat center/contain;
}
