.recommendations {
    &__buttons-block {
        & > button:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__manage-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    }

    &__region-search {
        display: flex;
        justify-content: space-between;
        margin: 20px auto 0;
        width: 350px;
        padding: 8px 15px;
        border: 1px solid rgba(18, 36, 67, 0.2);
        border-radius: 8px;
        background-color: #fff;

        input {
            flex-grow: 1;
            font-family: inherit;
            font-size: 16px;
            padding-right: 15px;
        }

        img {
            opacity: 0.6;
            width: 16px;
            height: 16px;
        }
    }

    &__filter {
        &-main-block {
            padding: 16px;
        }

        &-mark {
            &-block {
                margin-top: 8px;
                padding: 16px;

                &-flex {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }

            &-status {
                label {
                    padding: 10px 15px 10px 40px;
                    background-color: transparent !important;
                }

                input {
                    &:checked + label {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }

    &__list {
        margin-top: 24px;
    }

    &__item {
        padding: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.3px;
        color: rgba(18, 36, 67, 0.8);

        &:not(:first-child) {
            margin-top: 10px;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            column-gap: 20px;

            span {
                padding-left: 5px;
                padding-right: 10px;
            }

            &-filters {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
            }
        }

        &-buttons {
            flex-shrink: 0;

            button {
                padding: 2px 2px 2px 8px;
            }

            img {
                width: 20px;
                height: 20px;
                opacity: 0.6;
            }
        }

        p {
            margin-top: 15px;
            padding: 15px 5px 0;
            border-top: 1px solid rgba(18, 36, 67, 0.2);

            &.reason {
                font-size: 14px;
            }

            &.terms span {
                display: block;
                margin-top: 3px;
                font-style: italic;
                font-size: 14px;
                opacity: 0.7;
            }
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            font-size: 14px;

            span {
                font-style: italic;
                opacity: 0.8;

                b {
                    font-weight: 600;
                    font-style: normal;
                }
            }
        }

        &-draggable {
            cursor: move;

            p:first-child {
                margin-top: 0;
                padding-top: 0;
                border-top: 0;
            }
        }

        &-marked-btn {
            padding: 5px 10px;
            font: inherit;
            font-size: 14px;
            letter-spacing: 0.2px;
            color: inherit;
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 5px;

            &.marked {
                background-color: #b1ebbc;
            }
        }
    }

    &__not-found {
        text-align: center;
    }
}

.recommendation {
    margin-top: 30px;

    &__question-title {
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
    }

    &__answer-title {
        font-size: 16px;
        font-weight: 600;
        opacity: 0.8;
    }

    &__content {
        margin-top: 15px;
    }

    &__conditions {
        &-item {
            border: 1px solid rgba(18, 36, 67, 0.2);
            overflow: hidden;

            &:not(:first-child) {
                margin-top: 5px;
            }

            label {
                font-size: 14px;
                padding: 10px 15px 10px 40px;
            }
        }
    }

    &__any-answers {
        border: 1px solid rgba(18, 36, 67, 0.2);
        border-radius: 8px;
        overflow: hidden;

        label {
            padding: 10px 15px 10px 40px;
        }
    }

    &__last-update {
        padding: 0 5px;
        font-size: 14px;
        text-align: right;

        span {
            font-style: italic;
        }
    }

    &__buttons {
        margin: 20px 0;
        text-align: center;
    }

    &__answers {
        &-item {
            margin-top: 2px;
            margin-left: 20px;

            &:first-child {
                margin-top: 5px;
            }
        }
    }

    &__conditions {
        &-title {
            margin: 15px 0;
            text-align: center;
        }
    }

    &__block {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;

            h4 span {
                font-size: 14px;
                font-weight: 500;
            }

            button {
                padding: 5px 10px;
                font: inherit;
                font-size: 14px;
                letter-spacing: 0.2px;
                color: inherit;
                border: 1px solid rgba(18, 36, 67, 0.2);
                border-radius: 5px;
            }
        }

        &-subtitle {
            margin-top: 15px;
            font-size: 14px;
            font-style: italic;
            padding-left: 5px;
            opacity: 0.8;
        }

        &-inputs {
            display: flex;
            align-items: center;
            column-gap: 10px;

            input {
                padding: 5px 10px;
                background-color: #fff;
                border: 1px solid rgba(18, 36, 67, 0.2);
                border-radius: 5px;
            }
        }

        &-overflow {
            overflow-y: auto;
            height: 350px;
            position: relative;

            &-inner {
                position: absolute;
                padding: 0 10px 10px 0;

                & > div {
                    h5 {
                        font-size: 14px;
                        padding-left: 10px;
                        padding-bottom: 3px;
                    }

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &-buttons {
            margin: 10px 5px 0 0;
            text-align: right;

            button {
                margin-left: 10px;
                font-family: inherit;
                font-weight: 500;
                color: inherit;
                opacity: 0.8;
            }
        }
    }

    &__moderating {
        border: 1px solid;

        &.rework {
            border-color: rgba(200, 118, 0, 0.4);
        }

        &.accepted {
            border-color: rgba(41, 129, 0, 0.4);
        }

        &.moderation {
            border-color: rgba(0, 99, 229, 0.4);
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            gap: 10px;
        }

        &-status {
            font-weight: 500;

            &.moderation {
                color: #0063e5;
            }

            &.rework {
                color: #c87600;
            }

            &.accepted {
                color: #298100;
            }
        }

        &-comments {
            display: flex;
            flex-direction: column-reverse;

            &.no-comments {
                justify-content: center;
                align-items: center;
                opacity: 0.7;
            }

            &-item {
                padding: 10px 0;
                font-weight: 400;

                &:not(:last-child) {
                    border-top: 1px solid rgba(128, 128, 128, 0.4);
                }
            }

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                font-size: 14px;

                span {
                    font-weight: 600;

                    &:not(:first-child) {
                        font-weight: 500;
                    }

                    &.author {
                        color: #0063e5;
                    }

                    &.you {
                        color: #298100;
                    }
                }
            }

            &-text {
                margin-top: 4px;
            }

            &-send {
                margin-top: 24px;
                text-align: center;
            }
        }

        &-buttons {
            display: flex;
            justify-content: center;
            gap: 12px;

            button.rework {
                background-color: rgba(200, 118, 0, 0.65);

                @media (hover: hover) {
                    &:hover {
                        background-color: #c87600;
                    }
                }
            }

            button.accepted {
                background-color: rgba(41, 129, 0, 0.65);

                @media (hover: hover) {
                    &:hover {
                        background-color: #298100;
                    }
                }
            }
        }
    }

    &__correcting {
        &-diff {
            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    padding: 5px 10px;
                    font: inherit;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    color: inherit;
                    border: 1px solid rgba(18, 36, 67, 0.2);
                    border-radius: 5px;
                }
            }

            &-block {
                span {
                    &.added {
                        font-style: italic;
                        color: #406619;
                        background-color: #eaf2c2;
                    }

                    &.removed {
                        font-style: italic;
                        color: #d45656;
                        background-color: #ffe5e3;
                        text-decoration: line-through 1px;
                    }
                }
            }
        }
    }
}

.recommendations-page {
    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-donwload-btn {
            height: 32px;
            padding: 0 10px;
            border-radius: 7px;
            background-color: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

            span {
                font-family: 'Gilroy', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 100%;
                color: #122443;
            }
        }
    }

    &__content {
        color: #122443;
    }

    &__block-title {
        margin: 32px 0 12px;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
    }

    &__list {
        padding: 16px;
        border-radius: 16px;
        background: #fff;

        &:not(:first-child) {
            margin-top: 15px;
        }

        &-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 120%;
        }

        h5 {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.3px;
        }

        &-answers {
            margin: 12px 0;

            &-item {
                padding: 12px;
                border-radius: 7px;
                background: #f4f7f9;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.3px;

                &:not(:first-child) {
                    margin-top: 8px;
                }

                span {
                    font-size: 10px;
                    line-height: 100%;
                    opacity: 0.5;
                }

                p {
                    margin-top: 4px;
                    line-height: 130%;
                }
            }
        }

        &-item {
            padding: 12px 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:last-child {
                padding-bottom: 0;
            }

            a {
                color: #0072ff;
                text-decoration: underline;

                @media (hover: hover) {
                    transition: opacity 0.3s;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 130%;
            }

            &-flex {
                display: flex;

                p {
                    margin-left: 5px;
                }

                span {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 100%;
                    border-radius: 7px;
                    background: linear-gradient(90deg, #4e54c8 0%, #8f94fb 100%);
                }
            }

            &-reason {
                margin-top: 12px;
                padding: 12px;
                border-radius: 7px;
                background: #f4f7f9;

                &-title {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 100%;
                    letter-spacing: 0.3px;
                    opacity: 0.5;
                }

                & > div:not(:first-child) {
                    margin-top: 5px;
                }

                p {
                    margin-top: 5px;
                    letter-spacing: 0.3px;
                }
            }
        }

        &-logos {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 20px;
            margin-top: 16px;

            &-title {
                margin-bottom: 10px;
                font-size: 12px;
                text-align: center;
            }

            &-flex {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                height: 28px;

                &.minzdrav {
                    img {
                        width: 100px;
                        height: 28px;
                    }
                }

                &.agency {
                    img {
                        width: 68px;
                        height: 28px;
                    }
                }

                &.testu {
                    img {
                        width: 73px;
                        height: 20px;
                    }
                }
            }
        }
    }

    &__highlighted-block {
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        color: #fff;
        // border: 1px solid rgba(128, 128, 128, 0.4);
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

        a {
            // color: #0072ff;

            color: #fff;
            text-decoration: underline;

            @media (hover: hover) {
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

.new-recoms {
    &__list {
        margin-top: 40px;
        padding: 0 24px;

        &-item {
            display: block;
            padding: 16px;
            color: #122443;
            border: 1px solid rgba(128, 128, 128, 0.4);
            cursor: pointer;

            &:not(:first-child) {
                margin-top: 12px;
            }

            &.rework {
                border-color: rgba(200, 118, 0, 0.4);
            }

            &.accepted {
                border-color: rgba(41, 129, 0, 0.4);
            }

            &.moderation {
                border-color: rgba(0, 99, 229, 0.4);
            }

            &.not-read {
                background-color: #ffebeb;
            }

            &-row {
                &-first,
                &-second,
                &-other {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    column-gap: 20px;
                }

                &-first,
                &-other {
                    p {
                        font-weight: 500;
                        line-height: 140%;
                        letter-spacing: 0.3px;
                    }
                }

                &-second,
                &-other {
                    margin-top: 4px;

                    span {
                        font-style: italic;
                        opacity: 0.8;

                        &.status {
                            font-weight: 500;
                            font-style: normal;

                            &.moderation {
                                color: #0063e5;
                            }

                            &.accepted {
                                color: rgb(41, 129, 0);
                            }

                            &.rework {
                                color: rgb(200, 118, 0);
                            }
                        }
                    }
                }

                &-other {
                    margin-top: 12px;
                    padding-top: 12px;
                    border-top: 1px solid rgba(128, 128, 128, 0.4);
                }
            }

            &-title {
                font-size: 16px;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.3px;

                span {
                    font-weight: 400;
                    font-style: italic;
                }
            }
        }
    }
}
