.school-page {
    &__wrapper {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
    }
    &__search{
        width: 100%;
        margin-bottom: 16px;
        position: relative;
        img{
            position: absolute;
            top: 12px;
            left: 12px
        }
        input{
            width: 100%;
            background: rgba(18, 36, 67, 0.05);
            border-radius: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            height: 40px;
            padding: 9px 12px 9px 40px;
            margin-bottom: 16px;
        }
    }

    &__plug {
        margin: auto;
        max-width: 170px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.32px;
        color: #122443;
    }

    &__qr-icon {
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        padding: 6px;
        background: #f4f7f9;
        border-radius: 7px;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: url(../img/ic-qr.svg) no-repeat center;
        }
    }
}