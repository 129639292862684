.common-page {
    width: 100%;
    max-width: 640px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    padding: 0 1.5rem 84px;
    color: #122443;

    hr {
        height: 1px;
        background-color: rgba(18, 36, 67, 0.1);
        margin: 24px 0;
    }

    &__header {
        padding: 24px;
        background: linear-gradient(180deg, #00c6ff 0%, #0072ff 100%);
        border-radius: 0 0 16px 16px;
        color: #fff;

        &-buttons {
            position: relative;
            margin-bottom: 20px;
            height: 32px;
        }

        &-center {
            text-align: center;

            h3 {
                color: inherit;
                margin: 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.4px;
            }
        }
    }

    &__content {
        margin-top: 24px;
        padding: 0 24px;
        color: #122443;

        &-block {
            margin-top: 30px;
        }
    }

    &__card {
        &-block {
            padding: 16px;

            &:not(:first-child) {
                margin-top: 10px;
            }

            h4 {
                font-size: 16px;
                font-style: italic;
                opacity: 0.7;
                padding-left: 5px;
            }

            textarea {
                width: 100%;

                &::placeholder {
                    font-family: inherit;
                    font-style: italic;
                    opacity: 0.7;
                }
            }
        }

        &-inner {
            padding: 10px;
            margin-top: 8px;
            font-family: inherit;
            font-size: 16px;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0.3px;
            color: rgba(18, 36, 67, 0.8);
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 8px;
            background-color: #f7f9ff;

            b {
                font-weight: 600;
            }

            &.full-width {
                display: block;
                width: 100%;
            }

            &.low-placeholder {
                &::placeholder {
                    opacity: 0.6;
                    font-style: italic;
                }
            }
        }
    }

    &__logo {
        margin: 40px 0;
        text-align: center;
    }
}

.button-with-icons {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    color: #122443;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.26px;
    font-family: inherit;
    text-align: left;
    transition: background-color 0.3s;

    span {
        flex-grow: 1;
        padding: 2px 12px 0 12px;
    }

    img {
        width: 22px;
        height: 22px;
    }

    &.active {
        background-color: rgba(0, 114, 255, 0.25);
    }
}

.styled-checkbox {
    overflow: hidden;

    &__wrapper {
        border: 1px solid rgba(18, 36, 67, 0.2);
        border-radius: 8px;
        overflow: hidden;

        label {
            padding: 10px 15px 10px 40px;
        }
    }

    label {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 7px;
        cursor: pointer;
        transition: background-color 0.35s;
        user-select: none;

        &:before {
            content: '';
            position: absolute;
            left: 10px;
            width: 20px;
            height: 20px;
            border: 1px solid #9abbce;
            border-radius: 2px;
            background: url('../img/checkbox.svg') no-repeat center center / 0%;
            transition: all 0.3s;
        }

        @media (hover: hover) {
            &:hover {
                background-color: #cee3ff;
            }
        }
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + label {
            background-color: #f1fcff;

            @media (hover: hover) {
                &:hover {
                    background-color: #cee3ff;
                }
            }
        }

        &:checked + label:before {
            border: 1px solid #2196f3;
            background-size: 60%;
        }
    }
}
