.settings-page {
    &__main {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    &__sections {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        background: #f4f7f9;
        border-radius: 20px 20px 0px 0px;
        margin: -1rem -1.5rem 0;
        animation-name: selectionUp;
        animation-duration: 0.6s;
        animation-timing-function: ease-out;

        &-wrapper {
            padding: 1.5rem;
        }

        &-title {
            opacity: 0.4;
            color: #122443;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }
    }

    &__footer {
        // margin-top: auto;
        padding: 1.5rem;
    }
}

.settings-sections {
    list-style: none;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 10px -3px rgba(38, 51, 73, 0.1);

    &__item {
        display: flex;
        padding: 1rem;
        align-items: center;
        cursor: pointer;

        svg {
            margin-right: 12px;
        }

        & + & {
            border-top: 0.5px solid rgba(18, 36, 67, 0.1);
        }

        font-family: "Gilroy";
        color: #122443;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2625px;

        &::after {
            content: "";
            width: 22px;
            height: 22px;
            margin-left: auto;
            border-radius: 4px;
            background-color: #f4f7f9;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../img/ic-next-black.svg);
            box-shadow: 0px 2px 10px -3px rgba(38, 51, 73, 0.1);
        }
    }
}

.logout-btn {
    width: 100%;
    height: 46px;
    border-radius: 7px;
    background: rgba(255, 90, 82, 0.2);

    font-family: "Gilroy";
    color: #ff5a52;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
}

.settings-details {
    list-style: none;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 10px -3px rgba(38, 51, 73, 0.1);

    &__item {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        color: #122443;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2625px;

        & + & {
            border-top: 0.5px solid rgba(18, 36, 67, 0.1);
        }

        p:nth-child(2) {
            opacity: 0.4;
        }
    }
}
