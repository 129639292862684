.alert-wrapper {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.5);
}

.alert {
    width: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;

    color: #122443;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.32px;

    padding: 1rem 2rem;
    background: #fff;
    border-radius: 20px;

    span {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 50%;
        margin-bottom: 1rem;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &_success {
        span {
            background: rgba(82, 194, 43, 0.16);

            &::after {
                background-image: url(../img/ic-success.svg);
            }
        }
    }

    &_error {
        span {
            background: rgba(194, 43, 43, 0.1);

            &::after {
                background-image: url(../img/ic-error.svg);
            }
        }
    }
}

.notice{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(19, 37, 68, 0.4);
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content{
        background-color: white;
        width: 400px;
        min-height: 0;
        padding: 25px 34px;
        border-radius: 16px;

        p{
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.26249998807907104px;
            text-align: center;
            opacity: 0.4;
            margin-bottom: 18px;
        }

        button{
            background-color: rgba(71, 160, 255, 0.2);
            border-radius: 7px;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.4000000059604645px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(71, 160, 255, 1);
            width: calc(100% - 74px);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
